export const APP_NAME = "Fat Bastard Wines";
export const APP_ENV = "local";
export const APP_URL = "http://cms.fatbastard.local";

// Local
//export const FAT_BASTARD_API_IMAGE_PATH ="http://cms.fatbastard.local/storage/";

// Stage
export const FAT_BASTARD_API_IMAGE_PATH ="https://fb-stage.stg7.optimalonline.co.za/storage/";

// Prod
//export const FAT_BASTARD_API_IMAGE_PATH ="";
