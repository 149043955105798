import React from 'react'
import { Link } from 'react-router-dom';

export default function RestaurantItem({ restaurant }) {
  return (
    <div className="recipe-box">
      <Link to={`restaurants/${restaurant.slug}`} >
        <div className="recipe-image-container">
          <img src={`https://fb-stage.stg7.optimalonline.co.za/storage/${restaurant.image}`} alt=""/>
          <div className="recipe-overlay">READ MORE</div>
        </div>
      
        <h3>{restaurant.title}</h3>
        <p style={{ fontSize: '16px', color: '#525252'}}>{restaurant.preview_text.slice(0, 100) + '...'}</p>
      </Link>
      
    </div>
  )
}
