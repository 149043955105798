import "./Header.scss";
import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import Brand from "../brand/Brand";
import NavDropDown from "../dropdown/NavDropDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import {
  faFacebookF,
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
// C:\wamp64\www\mysites\FatBastard\fat-bastard-client-2019\public\resources\img'

class Header extends Component {
  constructor(props) {
    super(props);
    this.hamby = React.createRef();
    this.navbarList = React.createRef();

    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  // Hide or show the menu.
  handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    this.navbarList.current.classList.remove("open");
      this.hamby.current.classList.remove("is-active");
    var visible = true;
    if(currentScrollPos > 200){
       visible = false;
  
    }

    this.setState({
      prevScrollpos: currentScrollPos,
      visible
    });
  };

  toggleMenu = () => {
    if (this.hamby.current.classList.contains("is-active")) {
      this.navbarList.current.classList.remove("open");
      this.hamby.current.classList.remove("is-active");
    } else {
      this.navbarList.current.classList.add("open");
      this.hamby.current.classList.add("is-active");
    }
  };

  render() {
    return (
      <Fragment>
        <div className={classnames("header_container", {
              "header-container-hidden": !this.state.visible
            })}
            id="header_container">
          <header
           className="header"
          >
            {/* <div style={{
              backgroundColor:'#5b2b81',
              color:'white',
              width:'calc(100% + 80px)',
              padding:'10px 40px',
              textAlign:'center', 
              fontSize: '14px',
              marginLeft: '-40px',
              marginRight: '-40px',
              lineHeight:'13px',
              height: '60px',
              display:'flex',
              justifyContent: 'center',
              alignItems: 'center'
              }}>COVID-19 REGULATIONS: WINE DELIVERY IS CURRENTLY PROHIBITED. ALL ORDERS WILL BE DELIVERED AS SOON AS REGULATIONS ALLOW.</div> */}
            <Brand color="light" />
            <button
              onClick={this.toggleMenu}
              ref={this.hamby}
              className="hamburger hamby hamburger--spin"
              type="button"
              id="hamby"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>

            <div className="social-icons social-icons-nav hsn-desktop">
              <ul className="social-icon-list">
              <li>
                  <a
                    href="https://shop.fatbastardwine.co.za/"
                    // style={{padding: '9px 8px 5px 6px'}}
                  >
                    <img src="/resources/img/Cart.png" alt="" width="20" height="20" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/FATbastardWineSA"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/FATbastardSA"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/fatbastardsa/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
              </ul>
            </div>
          </header>

          <nav>
            <ul
              ref={this.navbarList}
              className="navbar_list navbar_list_center"
            >
              
              <li>
                <NavLink
                  activeclassname="menu-selected"
                  onClick={this.toggleMenu}
                  to="/"
                  exact
                  className="nav-NavLink p-t-5 p-b-0"
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeclassname="menu-selected"
                  onClick={this.toggleMenu}
                  to="/the-fat-bastards"
                  exact
                  className="nav-NavLink p-t-5 p-b-0"
                >
                  The FAT <i style={{ lineHeight: 0 }}>bastards</i>
                </NavLink>
              </li>
              <NavDropDown toggleMenu={this.toggleMenu}
                items={[
                  {
                    link: "/vib/register",
                    name: "Register"
                  },
                  { link: "/restaurants", name: "Restaurants" }
                ]}
              />
              <li>
                <NavLink
                  activeclassname="menu-selected"
                  onClick={this.toggleMenu}
                  to="/fat-inspirations"
                  exact
                  className="nav-NavLink p-t-5 p-b-0 "
                >
                  FAT <i>Inspirations</i>
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeclassname="menu-selected"
                  onClick={this.toggleMenu}
                  to="/social-feed"
                  exact
                  className="nav-NavLink p-t-5 p-b-0"
                >
                  Social Feed
                </NavLink>
              </li>
              <li>
                <a
                  activeclassname="menu-selected"
                  href="https://shop.fatbastardwine.co.za/"
                  className="nav-NavLink p-t-5 p-b-0"
                  style={{ color: '#d6b052' }}
                >
                  Shop
                </a>
              </li>
              <li>
                <NavLink
                  activeclassname="menu-selected"
                  onClick={this.toggleMenu}
                  to="/contact-us"
                  exact
                  className="nav-NavLink p-t-5 p-b-0"
                >
                  Get In Touch
                </NavLink>
              </li>

              <li>
                <div className="social-icons hsn-mobi">
                  <ul className="social-icon-list">
                    <li>
                      <a
                        href="https://www.facebook.com/FATbastardWineSA"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/FATbastardSA"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/fatbastardsa/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </Fragment>
    );
  }
}

export default Header;
