import "./WineDetail.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { fetchWine } from "../../actions";
import ReactHtmlParser from "react-html-parser";
import Dropdown from "../dropdown/Dropdown";
import Banner from "../slider/Banner";
import Button from "../buttons/Button";
import RecipeList from "../recipes/RecipeList";
import Gallery from "../gallery/Gallery";
import { syllabify } from "../../utils/functions";
import Loader from "../loader/Loader";
import {Link} from 'react-router-dom'

class WineDetail extends Component {
  componentDidMount() {
    this.props.fetchWine(this.props.match.params.slug);
  }

  renderTitle(title) {
    return title[0].map((item, index) => {
      return <p key={index}>{item}</p>;
    });
  }

  renderWine = () => {
    if (!this.props.selectedWine) {
      return <Loader />;
    }

    const title = [this.props.selectedWine.title].map(syllabify);

    const { image_path, content, theme } = this.props.selectedWine;

    const shopLinks = {
      'chardonnay': 'https://shop.fatbastardwine.co.za/?product=chardonnay',
      'suavignon-blanc': 'https://shop.fatbastardwine.co.za/?product=sauvignon-blanc',
      'chenin-blanc': 'https://shop.fatbastardwine.co.za/?product=chenin-blanc',
      'rose': 'https://shop.fatbastardwine.co.za/?product=rose',
      'pinotage': 'https://shop.fatbastardwine.co.za/?product=fat-bastard-pinotage',
      'cabernet-sauvignon': 'https://shop.fatbastardwine.co.za/?product=cabernet-sauvignon',
      'merlot': 'https://shop.fatbastardwine.co.za/?product=merlot',
      'shiraz': 'https://shop.fatbastardwine.co.za/?product=shiraz',
      'the-golden-reserve': 'https://shop.fatbastardwine.co.za/?product=the-golden-reserve',
      '2L-BIB-Chardonnay': 'https://shop.fatbastardwine.co.za/?product=2l-bib-chardonnay'
    }

    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`FAT bastard wines | The Fat bastards | ${this.props.selectedWine.title}`}</title>
          <meta
            name="description"
            content={this.props.selectedWine.meta_description}
          />
          <link rel="canonical" href={window.location} />
        </Helmet>

        <div className="ui container">
          <div className="wine-detail">
            <div className="wine-detail-display">
              <div className="wine-detail-panel wine-detail-image">
                <div className="wine-img-underlay">
                  {this.renderTitle(title)}
                </div>
                <img
                  src={`https://fb-stage.stg7.optimalonline.co.za/storage/${image_path}`}
                  alt=""
                />
              </div>

              <div className="wine-detail-panel wine-detail-content">
                {/* <div className="wine-detail-subtitle">
                  {ReactHtmlParser(subtitle)}
                </div> */}
                <h2 className={theme}>{title}</h2>
                {ReactHtmlParser(content)}
                {
                  this.props.selectedWine.in_shop === 1 && (
                    <a href={shopLinks[this.props.match.params.slug] ? shopLinks[this.props.match.params.slug] : 'https://shop.fatbastardwine.co.za'} className="btn purple fb_shopnow-btn">SHOP NOW</a>
                  )
                }
                <Link to="/where-to-buy" className="btn purple fb_shopnow-btn">WHERE TO BUY</Link>
                <Dropdown
                  items={this.props.selectedWine.tastingNotes}
                  theme={theme}
                />
                
              </div>
            </div>
            <Button
              toUrl="/the-fat-bastards"
              color="purple"
              title="BACK TO WINES"
            />
            <div style={{marginBottom: '30px'}} />
            {
              this.props.match.params.slug === 'the-golden-reserve' && (
                <div className=" fb_youtube-cont">
                  <div className="ui embed">
                      <iframe width="560" height="315" src="https://www.youtube.com/embed/MA1CJXGU1oM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </div>
              )
            }
            {
              this.props.selectedWine.recipes && (
                <RecipeList
                  showHeader={true}
                  recipes={this.props.selectedWine.recipes}
                  classNames={["centered"]}
                />
              )
            }
            
          </div>
        </div>
        <Gallery pageId={2} />
      </React.Fragment>
    );
  };

  render() {
    if (!this.props.isVerified) {
      return <Loader />;
    }

    return (
      <div>
        {/* <div style={{ height: "320px" }}> */}
        <Banner
          title="THE FAT <i>bastards</i>"
          breadcrumbs="HOME / THE FAT BASTARDS"
          subtitle={`
           <p>The only thing better than a <span style="color: rgb(214, 176, 82);">glass of wine</span> with wonderful colour and rich, round palate is another glass of the <span style="color: rgb(214, 176, 82);">same wine.</span></p>
          `}
        />
        {/* </div> */}
        {this.renderWine()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedWine: state.wines[ownProps.match.params.slug],
    isVerified: state.isVerified
  };
};

export default connect(
  mapStateToProps,
  {
    fetchWine
  }
)(WineDetail);
