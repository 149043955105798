import "./Landing.scss";

import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchPage } from "../../actions";
import { Helmet } from "react-helmet";
import Content from "../content/Content";
import HomeSlider from "../slider/HomeSlider";
import VideoDetail from "../video/VideoDetail";
import Wines from "../wines/Wines";
import Vib from "../vib/Vib";
import Gallery from "../gallery/Gallery";
import Recipes from "../recipes/Recipes";
import Button from "../buttons/Button";
import SocialBanner from "../social-feed/SocialBanner";
import Contact from "../forms/Contact";

class LandingPage extends Component {
  componentDidMount() {
    this.props.fetchPage("home");
  }

  render() {
    if (!this.props.isVerified) {
      return <div>loading</div>;
    }

    return (
      <React.Fragment>
         <Helmet>
          <meta charSet="utf-8" />
          <title>FAT bastard wines | Home</title>
          <meta name="description" content="Full-bodied wines to be enjoyed with bellyfuls of laughs and good-natured cheer. Whatever the celebration there’s a FATbastard(or two) waiting tobe opened." />
          <link rel="canonical" href={window.location} />
        </Helmet>
        <div
          style={{
            marginBottom: "120px",
            // height: "370px",
            background: "#D6B052"
          }}
        >
          <HomeSlider />
        </div>
        <div className="ui container">
          <Content item={this.props.page} animated showHeader />
        </div>

        <div className="container-small">
          <VideoDetail videoId={1} />
        </div>

        <div className="ui container">
          <Wines amount={window.innerWidth < 1025 ? 4 : 3} />
          <div id="vib"></div>
          <Button
            toUrl="/the-fat-bastards"
            color="purple"
            title="MORE WINES"
            centered
          />

          <Vib showHeader />
        </div>

        <Gallery pageId={1} />

        <div className="ui container">
          <Recipes showHeader={true} amount={4} centered={false} />
          <Button
            toUrl="/fat-inspirations"
            color="purple"
            title="MORE RECIPES"
            centered
          />
        </div>

        <SocialBanner />
        <Contact />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    page: state.page,
    isVerified: state.isVerified
  };
};

export default connect(
  mapStateToProps,
  {
    fetchPage
  }
)(LandingPage);
