import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";

export default function SocialIconList() {
  return (
    <React.Fragment>
        <ul className="social-icon-list">
          <li>
            <a href="https://www.facebook.com/FATbastardWineSA" rel="noopener noreferrer" target="_blank">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/FATbastardSA" rel="noopener noreferrer" target="_blank">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/fatbastardsa/" rel="noopener noreferrer" target="_blank">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </li>
        </ul>
    </React.Fragment>
  )
}
